const actions = {
	CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGOUT: 'LOGOUT',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_ERROR: 'LOGIN_ERROR',
	USER_PERMISSIONS: 'USER_PERMISSIONS',

	checkAuthorization: () => ({
		type: actions.CHECK_AUTHORIZATION
	}),

	login: (token = false) => ({
		type: actions.LOGIN_REQUEST,
		payload: { token },
	}),

	logout: () => ({
		type: actions.LOGOUT,
	}),

	permissions: (permissions = []) => ({
		type: actions.USER_PERMISSIONS,
		payload: { permissions }
	})
};
export default actions;
